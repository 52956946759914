import content from '@/content/staticContentForUI';
import { VALIDATION_RULES } from '@/utils/formHelpers/validation';
import {
  BIO_FIELD,
  STATUS_AND_CONTRACT_FORM,
  PERSONAL_DETAILS_FORM,
  PUBLIC_DETAILS_FORM,
  PROFILE_CREATE_FORM,
  PROFILE_STATUS_FORM,
  REQUIRED_TO_CREATE,
  SOCIAL_HANDLE,
} from '@/utils/formHelpers/tags';

const fieldText = content.formFields;

/**
 * This is the source of truth for ambassador form fields. Factory functions and helpers use
 * this data to create form components.
 *
 * Value for 'attributeId' should match corresponding attribute name on Ambassador model
 * ('src/models/v2/Ambassador.js').
 *
 * Some fields use custom validation not listed here (e.g., phone). In those cases, validation is
 * entered directly into the component, because those validation functions need params or other
 * data from component's Vue instance (e.g., 'this.ambassador.publish_status').
 *
 *
 * Follow this spec when adding new fields:
 *
 * {
 *  attributeId: {String} - Unique identifier (must be snake_case).
 *  fieldText: {Object} - Text content (Strings) that displays to end user (e.g., input label, placeholder).
 *  tags: {Array} - Tags to help with filtering (e.g., form's refName, category).
 *  validation: {Array} - List of variables that reference functions in VALIDATION_RULES.
 * }
 */

const FORM_FIELD_DETAILS = [
  {
    attributeId: 'first_name',
    defaultValue: '',
    fieldText: fieldText.first_name,
    tags: [PROFILE_CREATE_FORM, PUBLIC_DETAILS_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'last_name',
    defaultValue: '',
    fieldText: fieldText.last_name,
    tags: [PROFILE_CREATE_FORM, PUBLIC_DETAILS_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'ambassador_type',
    defaultValue: '',
    fieldText: fieldText.ambassador_type,
    tags: [PROFILE_CREATE_FORM, PUBLIC_DETAILS_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'email',
    defaultValue: '',
    fieldText: fieldText.email,
    tags: [PROFILE_CREATE_FORM, PERSONAL_DETAILS_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required, VALIDATION_RULES.email],
  },
  {
    attributeId: 'store_id',
    defaultValue: '',
    fieldText: fieldText.store_id,
    tags: [PROFILE_CREATE_FORM, PUBLIC_DETAILS_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'profile_locale',
    defaultValue: '',
    fieldText: fieldText.profile_locale,
    tags: [PROFILE_CREATE_FORM, PERSONAL_DETAILS_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'start_date',
    defaultValue: '',
    fieldText: fieldText.start_date,
    tags: [PROFILE_CREATE_FORM, STATUS_AND_CONTRACT_FORM, REQUIRED_TO_CREATE],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'phone',
    defaultValue: '',
    fieldText: fieldText.phone,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'studio_business_name',
    defaultValue: '',
    fieldText: fieldText.studio_business_name,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'relationship_manager_name',
    defaultValue: '',
    fieldText: fieldText.relationship_manager_name,
    tags: [STATUS_AND_CONTRACT_FORM],
    validation: [],
  },
  {
    attributeId: 'relationship_manager_email',
    defaultValue: '',
    fieldText: fieldText.relationship_manager_email,
    tags: [STATUS_AND_CONTRACT_FORM],
    validation: [VALIDATION_RULES.email],
  },
  {
    attributeId: 'address_search',
    defaultValue: '',
    fieldText: fieldText.address_search,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'home_address',
    defaultValue: '',
    fieldText: fieldText.home_address,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'country',
    defaultValue: '',
    fieldText: fieldText.country,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'address_line_1',
    defaultValue: '',
    fieldText: fieldText.address_line_1,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'address_line_2',
    defaultValue: '',
    fieldText: fieldText.address_line_2,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'city',
    defaultValue: '',
    fieldText: fieldText.city,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'state',
    defaultValue: '',
    fieldText: fieldText.state,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'zip_code',
    defaultValue: '',
    fieldText: fieldText.zip_code,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'emergency_address',
    defaultValue: '',
    fieldText: fieldText.emergency_address,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_country',
    defaultValue: '',
    fieldText: fieldText.contact_country,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_address_line_1',
    defaultValue: '',
    fieldText: fieldText.contact_address_line_1,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_address_line_2',
    defaultValue: '',
    fieldText: fieldText.contact_address_line_2,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_city',
    defaultValue: '',
    fieldText: fieldText.contact_city,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_state',
    defaultValue: '',
    fieldText: fieldText.contact_state,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_zip_code',
    defaultValue: '',
    fieldText: fieldText.contact_zip_code,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_name',
    defaultValue: '',
    fieldText: fieldText.contact_name,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'contact_email',
    defaultValue: '',
    fieldText: fieldText.contact_email,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [VALIDATION_RULES.email],
  },
  {
    attributeId: 'contact_phone',
    defaultValue: '',
    fieldText: fieldText.contact_phone,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'gender',
    defaultValue: '',
    fieldText: fieldText.gender,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'language',
    defaultValue: '',
    fieldText: fieldText.language,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'birth_date',
    defaultValue: '',
    fieldText: fieldText.birth_date,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [VALIDATION_RULES.birth_date],
  },
  {
    attributeId: 'end_date',
    defaultValue: '',
    fieldText: fieldText.end_date,
    tags: [STATUS_AND_CONTRACT_FORM],
    validation: [],
  },
  {
    attributeId: 'top_size',
    defaultValue: '',
    fieldText: fieldText.top_size,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'bottom_size',
    defaultValue: '',
    fieldText: fieldText.bottom_size,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'shoe_size',
    defaultValue: '',
    fieldText: fieldText.shoe_size,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'outerwear_size',
    defaultValue: '',
    fieldText: fieldText.outerwear_size,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'gift_card_number',
    defaultValue: '',
    fieldText: fieldText.gift_card_number,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [VALIDATION_RULES.gift_card_number],
  },
  {
    attributeId: 'communication_consent',
    defaultValue: false,
    fieldText: fieldText.communication_consent,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'legal_consent',
    defaultValue: false,
    fieldText: fieldText.legal_consent,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'experience_modelling_or_casting',
    defaultValue: '',
    fieldText: fieldText.experience_modelling_or_casting,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'campaign_status',
    defaultValue: false,
    fieldText: fieldText.campaign_status,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'dietary_restrictions',
    defaultValue: '',
    fieldText: fieldText.dietary_restrictions,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'main_discipline',
    defaultValue: '',
    fieldText: fieldText.main_discipline,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'sub_discipline',
    defaultValue: '',
    fieldText: fieldText.sub_discipline,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'biography',
    defaultValue: '',
    fieldText: fieldText.biography,
    tags: [PUBLIC_DETAILS_FORM, BIO_FIELD],
    validation: [VALIDATION_RULES.biography],
  },
  {
    attributeId: 'fact_1',
    defaultValue: '',
    fieldText: fieldText.fact_1,
    tags: [PUBLIC_DETAILS_FORM, BIO_FIELD],
    validation: [VALIDATION_RULES.fact_1],
  },
  {
    attributeId: 'fact_2',
    defaultValue: '',
    fieldText: fieldText.fact_2,
    tags: [PUBLIC_DETAILS_FORM, BIO_FIELD],
    validation: [VALIDATION_RULES.fact_2],
  },
  {
    attributeId: 'fact_3',
    defaultValue: '',
    fieldText: fieldText.fact_3,
    tags: [PUBLIC_DETAILS_FORM, BIO_FIELD],
    validation: [VALIDATION_RULES.fact_3],
  },
  {
    attributeId: 'fact_4',
    defaultValue: '',
    fieldText: fieldText.fact_4,
    tags: [PUBLIC_DETAILS_FORM, BIO_FIELD],
    validation: [VALIDATION_RULES.fact_4],
  },
  {
    attributeId: 'fact_5',
    defaultValue: '',
    fieldText: fieldText.fact_5,
    tags: [PUBLIC_DETAILS_FORM, BIO_FIELD],
    validation: [VALIDATION_RULES.fact_5],
  },
  {
    attributeId: 'facebook',
    defaultValue: '',
    fieldText: fieldText.facebook,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [VALIDATION_RULES.facebook],
  },
  {
    attributeId: 'instagram',
    defaultValue: '',
    fieldText: fieldText.instagram,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [VALIDATION_RULES.instagram],
  },
  {
    attributeId: 'instagram_2',
    defaultValue: '',
    fieldText: fieldText.instagram_2,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [VALIDATION_RULES.instagram],
  },
  {
    attributeId: 'strava',
    defaultValue: '',
    fieldText: fieldText.strava,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [VALIDATION_RULES.strava],
  },
  {
    attributeId: 'tiktok',
    defaultValue: '',
    fieldText: fieldText.tiktok,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [],
  },
  {
    attributeId: 'twitter',
    defaultValue: '',
    fieldText: fieldText.twitter,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [VALIDATION_RULES.twitter],
  },
  {
    attributeId: 'youtube',
    defaultValue: '',
    fieldText: fieldText.youtube,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [VALIDATION_RULES.youtube],
  },
  {
    attributeId: 'wechat',
    defaultValue: '',
    fieldText: fieldText.wechat,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [],
  },
  {
    attributeId: 'weibo',
    defaultValue: '',
    fieldText: fieldText.weibo,
    tags: [PUBLIC_DETAILS_FORM, SOCIAL_HANDLE],
    validation: [],
  },
  {
    attributeId: 'website',
    defaultValue: '',
    fieldText: fieldText.website,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [VALIDATION_RULES.website],
  },
  {
    attributeId: 'instagram_followers',
    defaultValue: null,
    fieldText: fieldText.instagram_followers,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [VALIDATION_RULES.integer_only],
  },
  {
    attributeId: 'affiliated_brands',
    defaultValue: '',
    fieldText: fieldText.affiliated_brands,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'mirror',
    defaultValue: false,
    fieldText: fieldText.mirror,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'profile_photo',
    defaultValue: null,
    fieldText: fieldText.profile_photo,
    tags: [],
    validation: [],
  },
  {
    attributeId: 'ambassador_status',
    defaultValue: '',
    fieldText: fieldText.ambassador_status,
    tags: [PROFILE_STATUS_FORM],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'termination_reason',
    defaultValue: '',
    fieldText: fieldText.termination_reason,
    tags: [PERSONAL_DETAILS_FORM, PROFILE_STATUS_FORM, STATUS_AND_CONTRACT_FORM],
    validation: [VALIDATION_RULES.required],
  },
  {
    attributeId: 'starting_status',
    defaultValue: '',
    fieldText: fieldText.starting_status,
    tags: [STATUS_AND_CONTRACT_FORM],
    validation: [],
  },
  {
    attributeId: 'product_categories',
    defaultValue: [],
    fieldText: fieldText.product_categories,
    tags: [PUBLIC_DETAILS_FORM],
    validation: [],
  },
  {
    attributeId: 'egiftcard_sent',
    defaultValue: false,
    fieldText: fieldText.egiftcard_sent,
    tags: [PERSONAL_DETAILS_FORM],
    validation: [],
  },
];

export { FORM_FIELD_DETAILS };
