import { setupLocalizationPlugin, t } from '@/utils/i18nextConfig';

// initialize i18next
setupLocalizationPlugin();

export default {
  // used in view AmbassadorsList.vue
  ambassadorsList: {
    mainHeading: t('Ambassadors'),
    listCountLabel: t('Ambassadors found'),
    noResults: t(
      'Sorry, no results were found. Please check the Ambassador name or change your filters.'
    ),
  },
  // used in component AmbassadorsListCard.vue
  ambassadorsListCard: {
    ambassadorLabel: t('Ambassador'),
    disciplineLabel: t('Discipline'),
    storeLabel: t('Home Store'),
  },
  // used in component AmbassadorsListExport.vue
  ambassadorsListExport: {
    exportButton: t('Export Results'),
  },
  // used in component AmbassadorsListFilters.vue
  ambassadorsListFilters: {
    clearButton: t('Clear Filters'),
    sectionTitle: t('Filters'),
    sectionHeadings: {
      ambassadorDetails: t('Ambassador Details'),
      location: t('Location'),
      personalDetails: t('Personal Details'),
    },
    publishStatus: {
      label: t('Published Status'),
      placeholder: t('Filter by Published Status'),
    },
    ambassadorType: {
      label: t('Ambassador Type'),
      placeholder: t('Filter by Type'),
    },
    mainDiscipline: {
      label: t('Main Discipline'),
      placeholder: t('Filter by Main Discipline'),
    },
    subDiscipline: {
      label: t('Sub Discipline/Sport'),
      placeholder: t('Filter by Sub Discipline'),
    },
    ambassadorStatus: {
      label: t('Ambassador Status'),
      placeholder: t('Filter by Status'),
    },
    country: {
      label: t('Country/Region'),
      placeholder: t('Filter by Country/Region'),
    },
    state: {
      label: t('State/Province/Region'),
      placeholder: t('Filter by State'),
      hint: t('(example: enter CA for California)'),
    },
    city: {
      label: t('City'),
      placeholder: t('Filter by City'),
    },
    store: {
      label: t('Store'),
      placeholder: t('Filter by Store'),
    },
    age: {
      label: t('Age Range'),
      placeholder: t('Filter by Age Range'),
    },
    gender: {
      label: t('Gender'),
    },
  },
  // used in component AmbassadorsListPagination.vue
  ambassadorsListPagination: {
    previousButton: t('Prev'),
    firstButton: t('First'),
    lastButton: t('Last'),
    nextButton: t('Next'),
  },
  // used in component AmbassadorsListSearch.vue
  ambassadorsListSearch: {
    placeholder: t('Search Name or ID'),
  },
  // used in component AMBModal.vue
  ambModal: {
    closeButton: t('Close'),
  },
  // used in component AMBPendingLabel.vue
  ambPendingLabel: {
    defaultText: t('Incomplete'),
  },
  // used in view LoginForm.vue
  loginForm: {
    mainHeading: t('Sign in to your lululemon account'),
    loginButton: t('Sign In'),
  },
  // used in router.js
  navBreadcrumbs: {
    ambassadorsList: t('Ambassadors'),
    profileCreate: t('Add Ambassador'),
    profileDetails: t('Profile'),
    profileUpdateStatus: t('Update Status'),
    // Routes for user management are not localized for MVP
    usersList: 'User Management Portal',
    userProfile: 'Profile',
    userProfileCreate: 'Add User',
  },
  // used in component NavHeader.vue
  navHeader: {
    logoutButton: t('Sign Out'),
  },
  // used in component PhotoGalleryCropper.vue
  photoGalleryCropper: {
    original: t('Original Image'),
    preview: t('Preview Image'),
    rotate: t('Rotate'),
    saveButton: t('Save'),
    cancelButton: t('Cancel'),
    instructions: {
      adjustFrame: t('Click and drag to adjust the cropping frame.'),
      preview: t('shows how the photo will look on the guest facing profile.'),
    },
  },
  // used in component PhotoGalleryMain.vue
  photoGalleryMain: {
    deleteButton: t('Delete This Photo'),
    profilePhotoButton: t('Make Profile Photo'),
    profilePhotoLabel: t('Current Profile Photo'),
    paginationLabel: {
      part1: t('of'),
      part2: t('photos'),
    },
    confirmDeleteAlert: {
      heading: t('Confirm Delete'),
      confirm: t('Are you sure?'),
      warning: t("You won't be able to undo this."),
      confirmButton: t('Delete'),
      cancelButton: t('Cancel'),
    },
  },
  // used in component PhotoGalleryUploader.vue
  photoGalleryUploader: {
    filePickerButtonMultiple: t('Add gallery photos'),
    filePickerButtonSingle: t('Add profile photo'),
    photoRequirements: {
      heading: t('File Requirements'),
      fileType: t('File Type: JPEG, JPG, or PNG'),
      maxSize: t('Max Size: 5MB'),
      orientation: t('Orientation: Vertical'),
      photoLimitLabel: t('Photo Limit:'),
    },
  },
  // used in view ProfileCreate.vue
  profileCreate: {
    mainHeading: t('Add Profile'),
    required: t('Required'),
    createButton: t('Create'),
    cancelButton: t('Cancel'),
  },
  // used in view ProfileDetails.vue
  profileDetails: {
    mainHeading: t('Profile'),
    uuidLabel: t('Ambassador UUID:'),
    oktaIdLabel: 'Okta ID:',
    oktaIdDefault: 'N/A',
  },
  // used in component ProfileDetailsAccordion.vue
  profileDetailsAccordion: {
    subtitle: {
      complete: t('Required fields complete'),
      incompleteSingle: t('Required field remaining'),
      incompletePlural: t('Required fields remaining'),
      photo: t('Add profile photo'),
    },
    closeButton: t('Close'),
    saveButton: t('Save'),
    editButton: t('Edit'),
    cancelButton: t('Cancel'),
    nextButton: t('Next'),
    photosButton: t('Add Photos'),
    documentsButton: t('Add Files'),
    onboardingEditButton: t('Add Details'),
  },
  // used in ProfileDetailsStatusAndContract.vue
  profileDetailsStatusAndContract: {
    documentsButton: t('Upload Contract'),
    filepickerButton: t('Choose Files'),
    fileRequirements: {
      heading: t('File Requirements'),
      fileType: t('File Type: PDF, JPEG, JPG, or PNG'),
    },
    formSectionHeaders: {
      documents: t('Documents'),
      contractDetails: t('Contract Details'),
    },
    confirmDeleteAlert: {
      heading: t('Confirm Delete'),
      confirm: t('Are you sure?'),
      warning: t('The document will be permanently removed from the profile.'),
      confirmButton: t('Delete'),
      cancelButton: t('Cancel'),
    },
    confirmationAlert: {
      heading: t('Confirm Status Change'),
      terminated: t(
        'Only select this option if their ambassadorship ends prior to the contract end dates. This option is typically selected when something goes wrong in the relationship.'
      ),
      inputLabel: t('Reason for Changing Status'),
      inputPlaceholder: 'Type your message here...',
      confirmButton: t('OK'),
      cancelButton: t('Cancel'),
    },
  },
  // used in component ProfileDetailsDocuments.vue
  profileDetailsDocuments: {
    filepickerButton: t('Choose Files'),
    fileRequirements: {
      heading: t('File Requirements'),
      fileType: t('File Type: PDF, JPEG, JPG, or PNG'),
    },
    confirmDeleteAlert: {
      heading: t('Confirm Delete'),
      confirm: t('Are you sure?'),
      warning: t('The document will be permanently removed from the profile.'),
      confirmButton: t('Delete'),
      cancelButton: t('Cancel'),
    },
  },
  // used in component ProfileDetailsFormField.vue
  profileDetailsFormField: {
    tooltip: t('Click to edit'),
  },
  // used in component ProfileDetailsMain.vue
  profileDetailsMain: {
    confirmationAlert: {
      heading: t('Confirm Status Change'),
      terminated: t(
        'Only select this option if their ambassadorship ends prior to the contract end dates. This option is typically selected when something goes wrong in the relationship.'
      ),
      inputLabel: t('Reason for Changing Status'),
      inputPlaceholder: 'Type your message here...',
      confirmButton: t('OK'),
      cancelButton: t('Cancel'),
    },
    expandAccordions: {
      instructions: t('Expand sections for details:'),
      collapseButton: t('Collapse All'),
      expandButton: t('Expand All'),
    },
    accordionMessage: {
      required: t('Please complete all fields with asterisk'),
      personal: t(
        'These personal details will not be made public, except for home city and country.'
      ),
      public: t(
        'These details will display on the guest facing published profile, along with home city, country, and profile photo.'
      ),
      photos: t('Please add a photo to use as the guest facing profile photo.'),
      editsDisabled: {
        generic: t('This profile cannot be edited at this time.'),
        archived: t('This profile has been archived. Only Content Ops team can edit details.'),
        published: t(
          'This profile has been submitted for publishing. Only Content Ops team can edit'
        ),
        inReview: t(
          'This profile has been submitted for review. Only Content Ops, Creative, or SSC teams can edit'
        ),
      },
    },
    accordionHeadings: {
      personal: t('Personal Details'),
      public: t('Guest Facing Details'),
      photos: t('Photos'),
      documents: t('Documents'),
      contractDetails: t('Contract Details'),
      statusAndContract: t('Status And Contract'),
    },
    formSectionHeaders: {
      contactInfo: t('Contact Information'),
      personalInfo: t('Personal Information'),
      moreInfo: t('More About You'),
      ambassadorDetails: t('Ambassador Details'),
      social: t('Social'),
    },
    formFieldNoteLabel: t('Note:'),
    unsavedChangesAlert: {
      heading: t('Unsaved Changes'),
      cancelEdits: t('Do you want to save your changes?'),
      cancelButton: t('Cancel'),
      confirmButton: t('Save'),
      collapseAccordions: t(
        'Please save or cancel any forms in progress before closing all sections.'
      ),
    },
  },
  // used in component ProfileDetailsPublishStatus.vue
  profileDetailsPublishStatus: {
    fieldLabel: t('Published Status'),
    submitButton: t('Submit for Review'),
    statusMessage: {
      status: {
        inProgress: t('Ready to publish.'),
        inReview: t('Waiting for approval to be published.'),
        incompletePublished: t('Complete profile.'),
        completeDefault: t('All required fields are complete.'),
      },
      details: {
        incompleteDraft: t('Complete required fields to submit for review.'),
        incompleteInReview: t('Complete required fields to submit for publishing'),
        completeDraft: t('Ready to submit for review.'),
        completeInReview: t('Ready to submit for publishing.'),
        restrictedEdits: t('Only Content Ops team can change this Published Status.'),
      },
    },
    confirmationAlert: {
      heading: t('Confirm Status Change'),
      confirm: t('Are you sure?'),
      unrestrictedEdits: t(
        'This will allow content to be edited by all users with access to this profile.'
      ),
      restrictedEdits: t('You will be unable to edit guest facing content after you confirm this.'),
      readyForReview: t(
        'Only Content Ops, Creative, or SSC teams will be able to edit guest facing content after you confirm this.'
      ),
      readyToPublish: t(
        'Only Content Ops team will be able to edit guest facing content after you confirm this'
      ),
      published: t('This will publish content to the guest facing website and App.'),
      archived: t('This profile will no longer be visible on the guest facing website and App.'),
      stillPublished: `The profile will remain published. If you're trying to remove content from the guest facing website and App, choose "Archived".`,
      confirmButton: t('OK'),
      cancelButton: t('Cancel'),
    },
    successMessage: t('Status updated'),
  },
  // used in component ProfileDetailsSummary.vue
  profileDetailsSummary: {
    ambassadorLabel: t('Ambassador'),
    viewProfileButton: t('View Published Profile'),
    statusLabel: t('Status'),
  },
  // used in view ProfileUpdateStatus.vue
  profileUpdateStatus: {
    mainHeading: t('Update Status'),
    required: t('Required'),
    updateButton: t('Update'),
    cancelButton: t('Cancel'),
    confirmationAlert: {
      heading: t('Confirm Status Change'),
      terminated: t(
        'Only select this option if their ambassadorship ends prior to the contract end dates. This option is typically selected when something goes wrong in the relationship.'
      ),
      confirmButton: t('OK'),
      cancelButton: t('Cancel'),
    },
  },
  // used in multiple components
  errorMessages: {
    // alert message displayed if 401 error occurs (expired AD session)
    sessionExpired: {
      alertHeading: t('Session Expired'),
      default: t(
        'Uh-oh! Your Active Directory session has expired. Please click OK to refresh your session and continue using the app.'
      ),
      exportResultsFailed: t(
        "Sorry! These results can't be exported, because your Active Directory session has expired. Please click OK to refresh your session and try again."
      ),
      formSubmissionFailed: t(
        "Sorry! The form can't be submitted, because your Active Directory session has expired. Please click OK to refresh your session and try again."
      ),
      profilePhotoUploadFailed: t(
        "Sorry! The photo can't be uploaded, because your Active Directory session has expired. Please click OK to refresh your session and try again."
      ),
      documentUploadFailed: t(
        "Sorry! The files can't be saved, because your Active Directory session has expired. Please click OK to refresh your session and try again."
      ),
    },
    // displayed on photo uploader modal
    photoGalleryUploader: {
      galleryFull: t("You've reached the photo limit."),
      invalidFileSize: {
        part1: t(
          "Uh-oh! Please make sure each photo is smaller than 5MB and try again. The gallery couldn't be updated, because"
        ),
        part2Singular: t('this file is the wrong size:'),
        part2Plural: t('these files are the wrong size:'),
      },
      invalidFileType: {
        part1: t(
          "Uh-oh! Please choose either JPG, JPEG, or PNG and try again. The gallery couldn't be updated, because"
        ),
        part2Singular: t('this is not a valid image file:'),
        part2Plural: t('these are not valid image files:'),
      },
      fileLimitExceeded: {
        part1: t('Sorry! That would exceed the photo limit. Please try again. You may upload'),
        part2Singular: t('more photo.'),
        part2Plural: t('more photos.'),
      },
    },
    // displayed as part of "validation rules" for individual form fields
    profileFormFields: {
      wordLimitExceeded: {
        part1: t("You've entered"),
        part2: t('words. Please limit to'),
        part3: t('words or less.'),
      },
      required: t('This field is required.'),
      invalidEmail: t('Please enter a valid email address.'),
      birthdate: t('Ambassador must be at least 18 years old.'),
      phone: t('Please enter a valid phone number. (example: +1 604 215 9300)'),
      giftCard: t('Gift card number should be 20 digits with no spaces.'),
      socialHandle: t("Something's not right with this social handle."),
      website: {
        invalid: t(
          "Please make sure you've entered a full valid website URL (example: https://www.shop.lululemon.com)."
        ),
        protocolInvalid: t('URL must start with "https://".'),
        hostMissing: t('Host is missing.'),
        pathMissing: t('Path is missing.'),
      },
      addressSearch: {
        countryMissing: t('Please select a Country before entering an address.'),
        searchFailed: t(
          "Sorry, an error occurred, and your search can't be completed right now. Please enter the address manually."
        ),
      },
      publishStatus: t(
        "Uh-oh! This profile isn't ready yet. Please make sure all required fields are complete before updating the Published Status."
      ),
      integerOnly: t('Please enter numbers only (no commas, decimals, or spaces).'),
      invalidLululemonEmail: 'Please ensure that a @lululemon.com email is used.',
    },
    // misc
    unknownError: t(
      'Sorry! An error has occurred. Please refresh the page or try again later. If this problem continues, submit a Service Now ticket to the Store Ambassador Team for support.'
    ),
    cannotAccessPage: `${t("Sorry! You don't have access to this page.")} ${t(
      'Please submit a Service Now ticket to the Store Ambassador Team for support.'
    )}`,
    duplicateEmail: t(
      'This email address already exists in our Ambassador database. Please enter a unique email address and re-submit.'
    ),
    formIncomplete: t(
      "Are you sure you want to leave this page? The information you've entered on this form will not be saved."
    ),
    formInvalid: t(
      "Uh-oh! There's an issue with the information you've entered for one or more fields. Please check the errors and re-submit."
    ),
    formSubmissionFailed: `${t(
      'Sorry, an error occurred, and the form could not be submitted.'
    )} ${t('Please submit a Service Now ticket to the Store Ambassador Team for support.')}`,
    loginFailed: t('Sorry! Something went wrong. Please try signing in again.'),
    statusUpdateRestricted: t(
      'Sorry! Since this profile is "Published", only Content Ops team can change Ambassador Status.'
    ),
  },
  // used in view UserProfile
  userProfile: {
    jobTitle: 'Job Title',
    email: 'Email',
    lastActive: 'Last Active',
    role: 'Role',
    roleClaims: 'Role-specific permissions (view only)',
    customClaims: 'Custom permissions',
    saveButton: 'Save',
    cancelButton: 'Cancel',
  },
  // used in view userProfileCreate.vue
  userProfileCreate: {
    mainHeading: 'Add User',
    placeholder: 'Search by email address',
    required: 'Required',
    name: 'Name',
    email: 'Email',
    role: 'Role',
    searchButton: 'Search',
    cancelButton: 'Cancel',
    saveButton: 'Add User',
    errorEmployeeDataMissing:
      'This user cannot be added. Employee details in Workday are incomplete. Name, email, and job title are required to add this user.',
    errorExistingProfile: 'A user with this email already has access to the Ambassador App.',
    errorEmailInvalid:
      'This user cannot be added. Please ensure that the email is spelled correctly and has Active Directory access. Please connect with this user to connect with their manager.',
  },
  // used in view UsersList.vue
  usersList: {
    mainHeading: 'User Management Portal',
    clearButton: 'Clear Results',
    addUserButton: 'Add new user',
    userNeedsProfile: {
      mainMessage: "Sorry, we couldn't find any matches for this email:",
      helpHeading: 'Search help:',
      helpText1: 'Ensure the email is spelled correctly and has Active Directory access.',
      helpText2:
        'If this user is assigned to a store, please verify their email address, job title, and store number are correct in Workday.',
      helpText3:
        'If email address and store number are invalid, please contact their manager to get their information updated in Workday.',
      helpText4: `If email address and store number are valid, search for the store in the database. If you can't find the store, then it hasn't been added yet through the system or is not yet active. Please get in touch with the NSO and RMS and Store team to get it set up.`,
    },
    userNeedsRole:
      'This user has no profile set up in the Ambassador Database. Please assign this user with a new role.',
  },
  // used in component UsersListCard.vue
  usersListCard: {
    lockLabel: 'Cannot be edited',
    role: 'Role',
    store: 'Store',
  },
  // used in component UsersListFilters.vue
  usersListFilters: {
    clearButton: 'Clear Filters',
    sectionTitle: 'Filters',
  },
  // used in component UsersListSearch.vue
  usersListSearch: {
    placeholder: 'Search by email',
  },
  // used in multiple components
  formErrorSnackbar: {
    backToForm: t('Back to Form'),
    confirmButton: t('OK'),
  },
  // used in multiple components
  /**
   * These form field names must be snake_case in order to be compatible with formHelpers
   * ('@/src/utils/formHelpers') and other methods related to form fields, because those
   * functions expect the field names to match their corresponding attribute IDs from the
   * Ambassadors Service.
   */
  formFields: {
    first_name: {
      label: t('First Name'),
    },
    last_name: {
      label: t('Last Name'),
    },
    ambassador_type: {
      label: t('Ambassador Type'),
      placeholder: t('Select Ambassador Type'),
    },
    email: {
      label: t('Contact Email'),
      hint: t('This field can only contain English characters.'),
    },
    store_id: {
      label: t('Ambassador Store'),
      placeholder: t('Select Ambassador Store'),
    },
    phone: {
      label: t('Contact Phone Number'),
      placeholder: t('Enter a phone number'),
    },
    studio_business_name: {
      label: t('Studio/Business Name'),
    },
    country: {
      label: t('Country/Region'),
      placeholder: t('Select Country/Region'),
    },
    address_line_1: {
      label: t('Home Address Line 1'),
    },
    address_line_2: {
      label: t('Home Address Line 2'),
    },
    city: {
      label: t('City'),
    },
    state: {
      label: t('State/Province/Region'),
    },
    zip_code: {
      label: t('Zip/Postal Code'),
    },
    relationship_manager_name: {
      label: t('Relationship Manager Name'),
    },
    relationship_manager_email: {
      label: t('Relationship Manager Email'),
    },
    contact_name: {
      label: t('Emergency Contact Name'),
    },
    contact_email: {
      label: t('Emergency Contact Email'),
      hint: t('This field can only contain English characters.'),
    },
    contact_phone: {
      label: t('Emergency Contact Phone'),
      placeholder: t('Enter a phone number'),
    },
    contact_country: {
      label: t('Country/Region'),
      placeholder: t('Select Country/Region'),
    },
    contact_address_line_1: {
      label: t('Home Address Line 1'),
    },
    contact_address_line_2: {
      label: t('Home Address Line 2'),
    },
    contact_city: {
      label: t('City'),
    },
    contact_state: {
      label: t('State/Province/Region'),
    },
    contact_zip_code: {
      label: t('Zip/Postal Code'),
    },
    gender: {
      label: t('Gender'),
      placeholder: t('Select a Gender'),
    },
    language: {
      label: t('Preferred Language'),
      placeholder: t('Select a Language'),
    },
    birth_date: {
      label: t('Birthdate'),
      placeholder: t('Select a Date'),
      note: t('Birthdate information cannot be shown.'),
      noteCTA: t('To update it, click here.'),
    },
    start_date: {
      label: t('Start Date'),
      placeholder: t('Select a Date'),
      hint: t(
        "If re-signing an ambassador, please don't change the start date. Only adjust the end date and contract status to reflect the new contract."
      ),
    },
    end_date: {
      label: t('End Date'),
      placeholder: t('Select a Date'),
      hint: t("Refers to the ambassador's offboarding date."),
    },
    top_size: {
      label: t('Top Size'),
      placeholder: t('Select a Size'),
    },
    bottom_size: {
      label: t('Bottom Size'),
      placeholder: t('Select a Size'),
    },
    shoe_size: {
      label: t('Shoe Size'),
      placeholder: t('Select a Size'),
      hint: t('Selection based on US sizes.'),
    },
    outerwear_size: {
      label: t('Outerwear Size'),
      placeholder: t('Select a Size'),
    },
    gift_card_number: {
      label: t('Gift Card Number'),
    },
    communication_consent: {
      label: t('Communication Consent'),
      checkboxLabel: t('Sign me up for lululemon emails (you can unsubscribe at any time).'),
      linkLabel: t('See our privacy policy for details.'),
      linkHref: 'https://info.lululemon.com/legal/privacy-policy',
      displayData: t('Subscribed'),
    },
    legal_consent: {
      label: t('Legal Consent'),
      checkboxLabel: t('Signed Hardcopy contract?'),
      displayData: t('Signed'),
    },
    sub_discipline: {
      label: t('Sub Discipline/Sport'),
      placeholder: t('Select Sub Discipline'),
    },
    biography: {
      label: t('Bio'),
      note: t(
        `Tips for writing a great bio:\n\nPlease write in the 3rd person (write as if someone is talking about you vs from "I"). Already have a bio written? Great! Feel free to use it, no need to reinvent the wheel. If you don't have a bio, consider telling us a bit about how you got to where you are, what you're doing now, and what you want to achieve in the future.`
      ),
      hint: t('word count:'),
      wordLimitLabel: t('150'),
      wordLimit: 150, // don't translate wordLimit, since this integer is used in javascript function
    },
    fact_1: {
      label: t("What is a goal you're working on?"),
      note: t(
        `When writing a goal that you're working on, please use declarative language like "I will" or "I am".`
      ),
      hint: t('word count:'),
      wordLimitLabel: t('50'),
      wordLimit: 50, // don't translate wordLimit, since this integer is used in javascript function
    },
    fact_2: {
      label: t('What is a book you read that made an impact on you?'),
      note: t(
        'Write the book title and cite the author in this format. E.g., The Happiness Advantage by Shawn Achor.'
      ),
      hint: t('word count:'),
      wordLimitLabel: t('50'),
      wordLimit: 50, // don't translate wordLimit, since this integer is used in javascript function
    },
    fact_3: {
      label: t('What is one thing that everyone should experience in your city?'),
      note: t(
        "You're the insider! What would you tell a visiting friend they can't miss in your city? Be specific."
      ),
      hint: t('word count:'),
      wordLimitLabel: t('50'),
      wordLimit: 50, // don't translate wordLimit, since this integer is used in javascript function
    },
    fact_4: {
      label: t("What's your favourite way to sweat?"),
      note: t(
        'Your goal is to provide value to someone reading this – provide specifics, suggest a workout (name the class, or hiking spot), so others can try the same workouts as you and sweat too.'
      ),
      hint: t('word count:'),
      wordLimitLabel: t('50'),
      wordLimit: 50, // don't translate wordLimit, since this integer is used in javascript function
    },
    fact_5: {
      label: t('Song you never get sick of listening to?'),
      note: t(
        'Please write the song title and cite the artist in this format. E.g., "Hey Ya!" by Outkast.'
      ),
      hint: t('word count:'),
      wordLimitLabel: t('50'),
      wordLimit: 50, // don't translate wordLimit, since this integer is used in javascript function
    },
    facebook: {
      label: t('Facebook'),
      placeholder: t('Pat.Smith'),
      prependInner: t('facebook.com/'),
      hrefDomain: 'facebook.com/',
      hint: t('This field can only contain English characters.'),
    },
    instagram: {
      label: t('Instagram'),
      placeholder: t('lululemon'),
      prependInner: t('instagram.com/'),
      hrefDomain: 'instagram.com/',
      hint: t('This field can only contain English characters.'),
    },
    instagram_2: {
      label: t('Additional Instagram Handle'),
      placeholder: t('lululemon'),
      prependInner: t('instagram.com/'),
      hrefDomain: 'instagram.com/',
      hint: t('This field can only contain English characters.'),
    },
    strava: {
      label: t('Strava'),
      placeholder: t('5389391'),
      prependInner: t('strava.com/athletes/'),
      hrefDomain: 'strava.com/athletes/',
    },
    tiktok: {
      label: t('TikTok'),
      placeholder: t('lululemon'),
      prependInner: t('tiktok.com/@'),
      hrefDomain: 'tiktok.com/@',
    },
    twitter: {
      label: t('Twitter'),
      placeholder: t('lululemon'),
      prependInner: t('twitter.com/'),
      hrefDomain: 'twitter.com/',
      hint: t('This field can only contain English characters.'),
    },
    youtube: {
      label: t('Youtube'),
      placeholder: t('lululemon'),
      prependInner: t('youtube.com/user/'),
      hrefDomain: 'youtube.com/user/',
      hint: t('This field can only contain English characters.'),
    },
    wechat: {
      label: t('WeChat'),
      placeholder: '',
      prependInner: '',
      hrefDomain: '',
    },
    weibo: {
      label: t('Weibo'),
      placeholder: '',
      prependInner: '',
      hrefDomain: '',
    },
    website: {
      label: t('Website URL'),
      placeholder: t('https://www.shop.lululemon.com'),
      hint: t('This field can only contain English characters.'),
    },
    instagram_followers: {
      label: t('Number of Instagram Followers'),
    },
    profile_photo: {
      label: '',
    },
    ambassador_status: {
      label: t('Ambassador Status'),
      placeholder: t('Select an Option'),
    },
    termination_reason: {
      label: t('Reason for Changing Status'),
    },
    profile_locale: {
      label: t('Language for Profile Creation'),
      placeholder: t('Select a Language'),
      hint: t('This selected language will be set and used for filling out profile information.'),
    },
    experience_modelling_or_casting: {
      label: t('Modelling Experience'),
      hint: `${t('If yes, please specify where and when (brand/campaign and year)')}.`,
    },
    dietary_restrictions: {
      label: t('Dietary Restrictions/Allergies'),
    },
    affiliated_brands: {
      label: t('Other brands you are affiliated/contracted with'),
    },
    campaign_status: {
      label: t('Interested in being featured in a brand campaign?'),
    },
    mirror: {
      label: t('MIRROR Instructor'),
      hint: t(
        'This field can be edited by SSC users only. SSC users should check this field if the Ambassador is a contracted MIRROR instructor.'
      ),
    },
    starting_status: {
      label: t('Contract Status'),
      placeholder: t('Select an Option'),
    },
    // the following fields are not attributes on Ambassador model, but they are on edit forms:
    address_search: {
      label: t('Search for Address'),
      hint: t('example: 1818 Cornwall Ave, Vancouver, BC'),
      placeholder: t('Enter Address'),
      note: t('Trouble searching for address?'),
      noteCTA: t('Edit Address Manually'),
    },
    home_address: {
      label: t('Home Address'),
    },
    emergency_address: {
      label: t('Emergency Contact Address'),
    },
    main_discipline: {
      label: t('Main Discipline'),
      placeholder: t('Select Main Discipline'),
    },
    product_categories: {
      label: t('Product Categories'),
      placeholder: t('Select Product Categories'),
      note: t('Choose all that apply:'),
    },
    egiftcard_sent: {
      label: t('E-Gift Card Sent'),
      checkboxLabel: t('E-Gift Card Sent to Ambassador'),
      displayData: t('E-Gift Card Sent'),
    },
  },
  /**
   * These items are displayed in drop-down menus for Vuetify input components
   * (<v-select>, <v-autocomplete>). Copy displayed on the front end is listed as 'label'.
   * Values that must match their counterparts in the Ambassadors Service are listed as 'id'.
   * Strings listed as 'id' should not be translated.
   *
   * Example:
   *    <v-select
   *      v-model="language"
   *      :items="languages"
   *      item-text="label"
   *      item-value="id"
   *    />
   */
  inputSelectionItems: {
    ageRanges: [
      {
        label: t('18-25'),
        low: 18,
        high: 25,
      },
      {
        label: t('26-35'),
        low: 26,
        high: 35,
      },
      {
        label: t('36-45'),
        low: 36,
        high: 45,
      },
      {
        label: t('46-60'),
        low: 46,
        high: 60,
      },
      {
        label: t('60+'),
        low: 61,
        high: 100,
      },
    ],
    apparelBottomSizes: [
      {
        label: t('Men26/XS'),
        id: 'Men26',
        gender: 'Male',
      },
      {
        label: t('Men28/XS'),
        id: 'Men28',
        gender: 'Male',
      },
      {
        label: t('Men30/S'),
        id: 'Men30',
        gender: 'Male',
      },
      {
        label: t('Men32/M'),
        id: 'Men32',
        gender: 'Male',
      },
      {
        label: t('Men34/L'),
        id: 'Men34',
        gender: 'Male',
      },
      {
        label: t('Men36/XL'),
        id: 'Men36',
        gender: 'Male',
      },
      {
        label: t('Men38/XXL'),
        id: 'Men38',
        gender: 'Male',
      },
      {
        label: t('Men40/XXXL'),
        id: 'Men40',
        gender: 'Male',
      },
      {
        divider: true,
      },
      {
        label: t('Women0'),
        id: 'Women0',
        gender: 'Female',
      },
      {
        label: t('Women2'),
        id: 'Women2',
        gender: 'Female',
      },
      {
        label: t('Women4'),
        id: 'Women4',
        gender: 'Female',
      },
      {
        label: t('Women6'),
        id: 'Women6',
        gender: 'Female',
      },
      {
        label: t('Women8'),
        id: 'Women8',
        gender: 'Female',
      },
      {
        label: t('Women10'),
        id: 'Women10',
        gender: 'Female',
      },
      {
        label: t('Women12'),
        id: 'Women12',
        gender: 'Female',
      },
      {
        label: t('Women14'),
        id: 'Women14',
        gender: 'Female',
      },
      {
        label: t('Women16'),
        id: 'Women16',
        gender: 'Female',
      },
      {
        label: t('Women18'),
        id: 'Women18',
        gender: 'Female',
      },
      {
        label: t('Women20'),
        id: 'Women20',
        gender: 'Female',
      },
    ],
    apparelOuterwearSizes: [
      {
        label: t('MenXS'),
        id: 'MenXS',
        gender: 'Male',
      },
      {
        label: t('MenS'),
        id: 'MenS',
        gender: 'Male',
      },
      {
        label: t('MenM'),
        id: 'MenM',
        gender: 'Male',
      },
      {
        label: t('MenL'),
        id: 'MenL',
        gender: 'Male',
      },
      {
        label: t('MenXL'),
        id: 'MenXL',
        gender: 'Male',
      },
      {
        label: t('MenXXL'),
        id: 'MenXXL',
        gender: 'Male',
      },
      {
        divider: true,
      },
      {
        label: t('Women0'),
        id: 'Women0',
        gender: 'Female',
      },
      {
        label: t('Women2'),
        id: 'Women2',
        gender: 'Female',
      },
      {
        label: t('Women4'),
        id: 'Women4',
        gender: 'Female',
      },
      {
        label: t('Women6'),
        id: 'Women6',
        gender: 'Female',
      },
      {
        label: t('Women8'),
        id: 'Women8',
        gender: 'Female',
      },
      {
        label: t('Women10'),
        id: 'Women10',
        gender: 'Female',
      },
      {
        label: t('Women12'),
        id: 'Women12',
        gender: 'Female',
      },
      {
        label: t('Women14'),
        id: 'Women14',
        gender: 'Female',
      },
    ],
    apparelShoeSizes: [
      {
        label: `${t('Men')}${t('7')}`,
        id: 'Men7',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('7.5')}`,
        id: 'Men7.5',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('8')}`,
        id: 'Men8',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('8.5')}`,
        id: 'Men8.5',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('9')}`,
        id: 'Men9',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('9.5')}`,
        id: 'Men9.5',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('10')}`,
        id: 'Men10',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('10.5')}`,
        id: 'Men10.5',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('11')}`,
        id: 'Men11',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('11.5')}`,
        id: 'Men11.5',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('12')}`,
        id: 'Men12',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('12.5')}`,
        id: 'Men12.5',
        gender: 'Male',
      },
      {
        label: `${t('Men')}${t('13')}`,
        id: 'Men13',
        gender: 'Male',
      },
      {
        divider: true,
      },
      {
        label: `${t('Women')}${t('5')}`,
        id: 'Women5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('5.5')}`,
        id: 'Women5.5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('6')}`,
        id: 'Women6',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('6.5')}`,
        id: 'Women6.5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('7')}`,
        id: 'Women7',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('7.5')}`,
        id: 'Women7.5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('8')}`,
        id: 'Women8',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('8.5')}`,
        id: 'Women8.5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('9')}`,
        id: 'Women9',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('9.5')}`,
        id: 'Women9.5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('10')}`,
        id: 'Women10',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('10.5')}`,
        id: 'Women10.5',
        gender: 'Female',
      },
      {
        label: `${t('Women')}${t('11')}`,
        id: 'Women11',
        gender: 'Female',
      },
    ],
    apparelTopSizes: [
      {
        label: t('MenXS'),
        id: 'MenXS',
        gender: 'Male',
      },
      {
        label: t('MenS'),
        id: 'MenS',
        gender: 'Male',
      },
      {
        label: t('MenM'),
        id: 'MenM',
        gender: 'Male',
      },
      {
        label: t('MenL'),
        id: 'MenL',
        gender: 'Male',
      },
      {
        label: t('MenXL'),
        id: 'MenXL',
        gender: 'Male',
      },
      {
        label: t('MenXXL'),
        id: 'MenXXL',
        gender: 'Male',
      },
      {
        divider: true,
      },
      {
        label: t('Women0'),
        id: 'Women0',
        gender: 'Female',
      },
      {
        label: t('Women2'),
        id: 'Women2',
        gender: 'Female',
      },
      {
        label: t('Women4'),
        id: 'Women4',
        gender: 'Female',
      },
      {
        label: t('Women6'),
        id: 'Women6',
        gender: 'Female',
      },
      {
        label: t('Women8'),
        id: 'Women8',
        gender: 'Female',
      },
      {
        label: t('Women10'),
        id: 'Women10',
        gender: 'Female',
      },
      {
        label: t('Women12'),
        id: 'Women12',
        gender: 'Female',
      },
      {
        label: t('Women14'),
        id: 'Women14',
        gender: 'Female',
      },
      {
        label: t('Women16'),
        id: 'Women16',
        gender: 'Female',
      },
      {
        label: t('Women18'),
        id: 'Women18',
        gender: 'Female',
      },
      {
        label: t('Women20'),
        id: 'Women20',
        gender: 'Female',
      },
    ],
    /**
     * This field shows only the 4 new "Contract Status" options in the drop-down menu,
     * but we must support the 2 old "Starting Status" values (returning, continuing) when
     * displaying saved data (when form is not in edit mode) for backward compatibility.
     */
    contractStatusLabels: {
      new: t('New from Pipeline'),
      active: t('Active'),
      offboarded: t('Offboarded'),
      extended: t('Extended'),
      resigned: t('Re-Signed'),
      returning: t('Re-onboarding'),
      continuing: t('Maintaining Journey'),
      terminated: t('Terminated'),
    },
    contractStatusOptions: [
      {
        id: 'resigned',
        label: t('Re-Signed'),
      },
      {
        id: 'new',
        label: t('New from Pipeline'),
      },
      {
        id: 'offboarded',
        label: t('Offboarded'),
      },
      {
        id: 'terminated',
        label: t('Terminated'),
      },
    ],
    countries: [
      {
        id: 'AF',
        label: t('Afghanistan'),
      },
      {
        id: 'AX',
        label: t('Aland Islands'),
      },
      {
        id: 'AL',
        label: t('Albania'),
      },
      {
        id: 'DZ',
        label: t('Algeria'),
      },
      {
        id: 'AS',
        label: t('American Samoa'),
      },
      {
        id: 'AD',
        label: t('Andorra'),
      },
      {
        id: 'AO',
        label: t('Angola'),
      },
      {
        id: 'AI',
        label: t('Anguilla'),
      },
      {
        id: 'AQ',
        label: t('Antarctica'),
      },
      {
        id: 'AG',
        label: t('Antigua and Barbuda'),
      },
      {
        id: 'AR',
        label: t('Argentina'),
      },
      {
        id: 'AM',
        label: t('Armenia'),
      },
      {
        id: 'AW',
        label: t('Aruba'),
      },
      {
        id: 'AU',
        label: t('Australia'),
      },
      {
        id: 'AT',
        label: t('Austria'),
      },
      {
        id: 'AZ',
        label: t('Azerbaijan'),
      },
      {
        id: 'BS',
        label: t('Bahamas'),
      },
      {
        id: 'BH',
        label: t('Bahrain'),
      },
      {
        id: 'BD',
        label: t('Bangladesh'),
      },
      {
        id: 'BB',
        label: t('Barbados'),
      },
      {
        id: 'BY',
        label: t('Belarus'),
      },
      {
        id: 'BE',
        label: t('Belgium'),
      },
      {
        id: 'BZ',
        label: t('Belize'),
      },
      {
        id: 'BJ',
        label: t('Benin'),
      },
      {
        id: 'BM',
        label: t('Bermuda'),
      },
      {
        id: 'BT',
        label: t('Bhutan'),
      },
      {
        id: 'BO',
        label: t('Bolivia'),
      },
      {
        id: 'BQ',
        label: t('Bonaire, Saint Eustatius and Saba '),
      },
      {
        id: 'BA',
        label: t('Bosnia and Herzegovina'),
      },
      {
        id: 'BW',
        label: t('Botswana'),
      },
      {
        id: 'BV',
        label: t('Bouvet Island'),
      },
      {
        id: 'BR',
        label: t('Brazil'),
      },
      {
        id: 'IO',
        label: t('British Indian Ocean Territory'),
      },
      {
        id: 'VG',
        label: t('British Virgin Islands'),
      },
      {
        id: 'BN',
        label: t('Brunei'),
      },
      {
        id: 'BG',
        label: t('Bulgaria'),
      },
      {
        id: 'BF',
        label: t('Burkina Faso'),
      },
      {
        id: 'BI',
        label: t('Burundi'),
      },
      {
        id: 'KH',
        label: t('Cambodia'),
      },
      {
        id: 'CM',
        label: t('Cameroon'),
      },
      {
        id: 'CA',
        label: t('Canada'),
      },
      {
        id: 'CV',
        label: t('Cape Verde'),
      },
      {
        id: 'KY',
        label: t('Cayman Islands'),
      },
      {
        id: 'CF',
        label: t('Central African Republic'),
      },
      {
        id: 'TD',
        label: t('Chad'),
      },
      {
        id: 'CL',
        label: t('Chile'),
      },
      {
        id: 'CN',
        label: t('China Mainland'),
      },
      {
        id: 'CX',
        label: t('Christmas Island'),
      },
      {
        id: 'CC',
        label: t('Cocos Islands'),
      },
      {
        id: 'CO',
        label: t('Colombia'),
      },
      {
        id: 'KM',
        label: t('Comoros'),
      },
      {
        id: 'CK',
        label: t('Cook Islands'),
      },
      {
        id: 'CR',
        label: t('Costa Rica'),
      },
      {
        id: 'HR',
        label: t('Croatia'),
      },
      {
        id: 'CU',
        label: t('Cuba'),
      },
      {
        id: 'CW',
        label: t('Curacao'),
      },
      {
        id: 'CY',
        label: t('Cyprus'),
      },
      {
        id: 'CZ',
        label: t('Czechia'),
      },
      {
        id: 'CD',
        label: t('Democratic Republic of the Congo'),
      },
      {
        id: 'DK',
        label: t('Denmark'),
      },
      {
        id: 'DJ',
        label: t('Djibouti'),
      },
      {
        id: 'DM',
        label: t('Dominica'),
      },
      {
        id: 'DO',
        label: t('Dominican Republic'),
      },
      {
        id: 'EC',
        label: t('Ecuador'),
      },
      {
        id: 'EG',
        label: t('Egypt'),
      },
      {
        id: 'SV',
        label: t('El Salvador'),
      },
      {
        id: 'GQ',
        label: t('Equatorial Guinea'),
      },
      {
        id: 'ER',
        label: t('Eritrea'),
      },
      {
        id: 'EE',
        label: t('Estonia'),
      },
      {
        id: 'ET',
        label: t('Ethiopia'),
      },
      {
        id: 'FK',
        label: t('Falkland Islands'),
      },
      {
        id: 'FO',
        label: t('Faroe Islands'),
      },
      {
        id: 'FJ',
        label: t('Fiji'),
      },
      {
        id: 'FI',
        label: t('Finland'),
      },
      {
        id: 'FR',
        label: t('France'),
      },
      {
        id: 'GF',
        label: t('French Guiana'),
      },
      {
        id: 'PF',
        label: t('French Polynesia'),
      },
      {
        id: 'TF',
        label: t('French Southern Territories'),
      },
      {
        id: 'GA',
        label: t('Gabon'),
      },
      {
        id: 'GM',
        label: t('Gambia'),
      },
      {
        id: 'GE',
        label: t('Georgia'),
      },
      {
        id: 'DE',
        label: t('Germany'),
      },
      {
        id: 'GH',
        label: t('Ghana'),
      },
      {
        id: 'GI',
        label: t('Gibraltar'),
      },
      {
        id: 'GR',
        label: t('Greece'),
      },
      {
        id: 'GL',
        label: t('Greenland'),
      },
      {
        id: 'GD',
        label: t('Grenada'),
      },
      {
        id: 'GP',
        label: t('Guadeloupe'),
      },
      {
        id: 'GU',
        label: t('Guam'),
      },
      {
        id: 'GT',
        label: t('Guatemala'),
      },
      {
        id: 'GG',
        label: t('Guernsey'),
      },
      {
        id: 'GN',
        label: t('Guinea'),
      },
      {
        id: 'GW',
        label: t('Guinea-Bissau'),
      },
      {
        id: 'GY',
        label: t('Guyana'),
      },
      {
        id: 'HT',
        label: t('Haiti'),
      },
      {
        id: 'HM',
        label: t('Heard Island and McDonald Islands'),
      },
      {
        id: 'HN',
        label: t('Honduras'),
      },
      {
        id: 'HK',
        label: t('Hong Kong SAR'),
      },
      {
        id: 'HU',
        label: t('Hungary'),
      },
      {
        id: 'IS',
        label: t('Iceland'),
      },
      {
        id: 'IN',
        label: t('India'),
      },
      {
        id: 'ID',
        label: t('Indonesia'),
      },
      {
        id: 'IR',
        label: t('Iran'),
      },
      {
        id: 'IQ',
        label: t('Iraq'),
      },
      {
        id: 'IE',
        label: t('Ireland'),
      },
      {
        id: 'IM',
        label: t('Isle of Man'),
      },
      {
        id: 'IL',
        label: t('Israel'),
      },
      {
        id: 'IT',
        label: t('Italy'),
      },
      {
        id: 'CI',
        label: t('Ivory Coast'),
      },
      {
        id: 'JM',
        label: t('Jamaica'),
      },
      {
        id: 'JP',
        label: t('Japan'),
      },
      {
        id: 'JE',
        label: t('Jersey'),
      },
      {
        id: 'JO',
        label: t('Jordan'),
      },
      {
        id: 'KZ',
        label: t('Kazakhstan'),
      },
      {
        id: 'KE',
        label: t('Kenya'),
      },
      {
        id: 'KI',
        label: t('Kiribati'),
      },
      {
        id: 'XK',
        label: t('Kosovo'),
      },
      {
        id: 'KW',
        label: t('Kuwait'),
      },
      {
        id: 'KG',
        label: t('Kyrgyzstan'),
      },
      {
        id: 'LA',
        label: t('Laos'),
      },
      {
        id: 'LV',
        label: t('Latvia'),
      },
      {
        id: 'LB',
        label: t('Lebanon'),
      },
      {
        id: 'LS',
        label: t('Lesotho'),
      },
      {
        id: 'LR',
        label: t('Liberia'),
      },
      {
        id: 'LY',
        label: t('Libya'),
      },
      {
        id: 'LI',
        label: t('Liechtenstein'),
      },
      {
        id: 'LT',
        label: t('Lithuania'),
      },
      {
        id: 'LU',
        label: t('Luxembourg'),
      },
      {
        id: 'MO',
        label: t('Macau SAR'),
      },
      {
        id: 'MK',
        label: t('Macedonia'),
      },
      {
        id: 'MG',
        label: t('Madagascar'),
      },
      {
        id: 'MW',
        label: t('Malawi'),
      },
      {
        id: 'MY',
        label: t('Malaysia'),
      },
      {
        id: 'MV',
        label: t('Maldives'),
      },
      {
        id: 'ML',
        label: t('Mali'),
      },
      {
        id: 'MT',
        label: t('Malta'),
      },
      {
        id: 'MH',
        label: t('Marshall Islands'),
      },
      {
        id: 'MQ',
        label: t('Martinique'),
      },
      {
        id: 'MR',
        label: t('Mauritania'),
      },
      {
        id: 'MU',
        label: t('Mauritius'),
      },
      {
        id: 'YT',
        label: t('Mayotte'),
      },
      {
        id: 'MX',
        label: t('Mexico'),
      },
      {
        id: 'FM',
        label: t('Micronesia'),
      },
      {
        id: 'MD',
        label: t('Moldova'),
      },
      {
        id: 'MC',
        label: t('Monaco'),
      },
      {
        id: 'MN',
        label: t('Mongolia'),
      },
      {
        id: 'ME',
        label: t('Montenegro'),
      },
      {
        id: 'MS',
        label: t('Montserrat'),
      },
      {
        id: 'MA',
        label: t('Morocco'),
      },
      {
        id: 'MZ',
        label: t('Mozambique'),
      },
      {
        id: 'MM',
        label: t('Myanmar'),
      },
      {
        id: 'NA',
        label: t('Namibia'),
      },
      {
        id: 'NR',
        label: t('Nauru'),
      },
      {
        id: 'NP',
        label: t('Nepal'),
      },
      {
        id: 'NL',
        label: t('Netherlands'),
      },
      {
        id: 'AN',
        label: t('Netherlands Antilles'),
      },
      {
        id: 'NC',
        label: t('New Caledonia'),
      },
      {
        id: 'NZ',
        label: t('New Zealand'),
      },
      {
        id: 'NI',
        label: t('Nicaragua'),
      },
      {
        id: 'NE',
        label: t('Niger'),
      },
      {
        id: 'NG',
        label: t('Nigeria'),
      },
      {
        id: 'NU',
        label: t('Niue'),
      },
      {
        id: 'NF',
        label: t('Norfolk Island'),
      },
      {
        id: 'KP',
        label: t('North Korea'),
      },
      {
        id: 'MP',
        label: t('Northern Mariana Islands'),
      },
      {
        id: 'NO',
        label: t('Norway'),
      },
      {
        id: 'OM',
        label: t('Oman'),
      },
      {
        id: 'PK',
        label: t('Pakistan'),
      },
      {
        id: 'PW',
        label: t('Palau'),
      },
      {
        id: 'PS',
        label: t('Palestinian Territory'),
      },
      {
        id: 'PA',
        label: t('Panama'),
      },
      {
        id: 'PG',
        label: t('Papua New Guinea'),
      },
      {
        id: 'PY',
        label: t('Paraguay'),
      },
      {
        id: 'PE',
        label: t('Peru'),
      },
      {
        id: 'PH',
        label: t('Philippines'),
      },
      {
        id: 'PN',
        label: t('Pitcairn'),
      },
      {
        id: 'PL',
        label: t('Poland'),
      },
      {
        id: 'PT',
        label: t('Portugal'),
      },
      {
        id: 'PR',
        label: t('Puerto Rico'),
      },
      {
        id: 'QA',
        label: t('Qatar'),
      },
      {
        id: 'CG',
        label: t('Republic of the Congo'),
      },
      {
        id: 'RE',
        label: t('Reunion'),
      },
      {
        id: 'RO',
        label: t('Romania'),
      },
      {
        id: 'RU',
        label: t('Russia'),
      },
      {
        id: 'RW',
        label: t('Rwanda'),
      },
      {
        id: 'BL',
        label: t('Saint Barthelemy'),
      },
      {
        id: 'SH',
        label: t('Saint Helena'),
      },
      {
        id: 'KN',
        label: t('Saint Kitts and Nevis'),
      },
      {
        id: 'LC',
        label: t('Saint Lucia'),
      },
      {
        id: 'MF',
        label: t('Saint Martin'),
      },
      {
        id: 'PM',
        label: t('Saint Pierre and Miquelon'),
      },
      {
        id: 'VC',
        label: t('Saint Vincent and the Grenadines'),
      },
      {
        id: 'WS',
        label: t('Samoa'),
      },
      {
        id: 'SM',
        label: t('San Marino'),
      },
      {
        id: 'ST',
        label: t('Sao Tome and Principe'),
      },
      {
        id: 'SA',
        label: t('Saudi Arabia'),
      },
      {
        id: 'SN',
        label: t('Senegal'),
      },
      {
        id: 'RS',
        label: t('Serbia'),
      },
      {
        id: 'CS',
        label: t('Serbia and Montenegro'),
      },
      {
        id: 'SC',
        label: t('Seychelles'),
      },
      {
        id: 'SL',
        label: t('Sierra Leone'),
      },
      {
        id: 'SG',
        label: t('Singapore'),
      },
      {
        id: 'SX',
        label: t('Sint Maarten'),
      },
      {
        id: 'SK',
        label: t('Slovakia'),
      },
      {
        id: 'SI',
        label: t('Slovenia'),
      },
      {
        id: 'SB',
        label: t('Solomon Islands'),
      },
      {
        id: 'SO',
        label: t('Somalia'),
      },
      {
        id: 'ZA',
        label: t('South Africa'),
      },
      {
        id: 'GS',
        label: t('South Georgia and the South Sandwich Islands'),
      },
      {
        id: 'KR',
        label: t('South Korea'),
      },
      {
        id: 'SS',
        label: t('South Sudan'),
      },
      {
        id: 'ES',
        label: t('Spain'),
      },
      {
        id: 'LK',
        label: t('Sri Lanka'),
      },
      {
        id: 'SD',
        label: t('Sudan'),
      },
      {
        id: 'SR',
        label: t('Suriname'),
      },
      {
        id: 'SJ',
        label: t('Svalbard and Jan Mayen'),
      },
      {
        id: 'SZ',
        label: t('Swaziland'),
      },
      {
        id: 'SE',
        label: t('Sweden'),
      },
      {
        id: 'CH',
        label: t('Switzerland'),
      },
      {
        id: 'SY',
        label: t('Syria'),
      },
      {
        id: 'TW',
        label: t('Taiwan'),
      },
      {
        id: 'TJ',
        label: t('Tajikistan'),
      },
      {
        id: 'TZ',
        label: t('Tanzania'),
      },
      {
        id: 'TH',
        label: t('Thailand'),
      },
      {
        id: 'TL',
        label: t('Timor Leste'),
      },
      {
        id: 'TG',
        label: t('Togo'),
      },
      {
        id: 'TK',
        label: t('Tokelau'),
      },
      {
        id: 'TO',
        label: t('Tonga'),
      },
      {
        id: 'TT',
        label: t('Trinidad and Tobago'),
      },
      {
        id: 'TN',
        label: t('Tunisia'),
      },
      {
        id: 'TR',
        label: t('Turkey'),
      },
      {
        id: 'TM',
        label: t('Turkmenistan'),
      },
      {
        id: 'TC',
        label: t('Turks and Caicos Islands'),
      },
      {
        id: 'TV',
        label: t('Tuvalu'),
      },
      {
        id: 'VI',
        label: t('U.S. Virgin Islands'),
      },
      {
        id: 'UG',
        label: t('Uganda'),
      },
      {
        id: 'UA',
        label: t('Ukraine'),
      },
      {
        id: 'AE',
        label: t('United Arab Emirates'),
      },
      {
        id: 'GB',
        label: t('United Kingdom'),
      },
      {
        id: 'US',
        label: t('United States'),
      },
      {
        id: 'UM',
        label: t('United States Minor Outlying Islands'),
      },
      {
        id: 'UY',
        label: t('Uruguay'),
      },
      {
        id: 'UZ',
        label: t('Uzbekistan'),
      },
      {
        id: 'VU',
        label: t('Vanuatu'),
      },
      {
        id: 'VA',
        label: t('Vatican'),
      },
      {
        id: 'VE',
        label: t('Venezuela'),
      },
      {
        id: 'VN',
        label: t('Vietnam'),
      },
      {
        id: 'WF',
        label: t('Wallis and Futuna'),
      },
      {
        id: 'EH',
        label: t('Western Sahara'),
      },
      {
        id: 'YE',
        label: t('Yemen'),
      },
      {
        id: 'ZM',
        label: t('Zambia'),
      },
      {
        id: 'ZW',
        label: t('Zimbabwe'),
      },
    ],
    genders: [
      {
        label: t('Female'),
        id: 'f',
      },
      {
        label: t('Male'),
        id: 'm',
      },
      {
        label: t('Non Binary / Third Gender'),
        id: 'nb',
      },
      {
        label: t('Prefer not to say'),
        id: 'u',
      },
    ],
    languages: [
      {
        label: t('Afar'),
        id: 'aa',
      },
      {
        label: t('Abkhazian'),
        id: 'ab',
      },
      {
        label: t('Avestan'),
        id: 'ae',
      },
      {
        label: t('Afrikaans'),
        id: 'af',
      },
      {
        label: t('Akan'),
        id: 'ak',
      },
      {
        label: t('Amharic'),
        id: 'am',
      },
      {
        label: t('Aragonese'),
        id: 'an',
      },
      {
        label: t('Arabic'),
        id: 'ar',
      },
      {
        label: t('Assamese'),
        id: 'as',
      },
      {
        label: t('Avaric'),
        id: 'av',
      },
      {
        label: t('Aymara'),
        id: 'ay',
      },
      {
        label: t('Azerbaijani'),
        id: 'az',
      },
      {
        label: t('Bashkir'),
        id: 'ba',
      },
      {
        label: t('Belarusian'),
        id: 'be',
      },
      {
        label: t('Bulgarian'),
        id: 'bg',
      },
      {
        label: t('Bihari languages'),
        id: 'bh',
      },
      {
        label: t('Bislama'),
        id: 'bi',
      },
      {
        label: t('Bambara'),
        id: 'bm',
      },
      {
        label: t('Bengali'),
        id: 'bn',
      },
      {
        label: t('Tibetan'),
        id: 'bo',
      },
      {
        label: t('Breton'),
        id: 'br',
      },
      {
        label: t('Bosnian'),
        id: 'bs',
      },
      {
        label: t('Catalan, Valencian'),
        id: 'ca',
      },
      {
        label: t('Chechen'),
        id: 'ce',
      },
      {
        label: t('Chamorro'),
        id: 'ch',
      },
      {
        label: t('Corsican'),
        id: 'co',
      },
      {
        label: t('Cree'),
        id: 'cr',
      },
      {
        label: t('Czech'),
        id: 'cs',
      },
      {
        label: t(
          'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic'
        ),
        id: 'cu',
      },
      {
        label: t('Chuvash'),
        id: 'cv',
      },
      {
        label: t('Welsh'),
        id: 'cy',
      },
      {
        label: t('Danish'),
        id: 'da',
      },
      {
        label: t('German'),
        id: 'de',
      },
      {
        label: t('Divehi, Dhivehi, Maldivian'),
        id: 'dv',
      },
      {
        label: t('Dzongkha'),
        id: 'dz',
      },
      {
        label: t('Ewe'),
        id: 'ee',
      },
      {
        label: t('Greek'),
        id: 'el',
      },
      {
        label: t('English'),
        id: 'en',
      },
      {
        label: t('Esperanto'),
        id: 'eo',
      },
      {
        label: t('Spanish, Castilian'),
        id: 'es',
      },
      {
        label: t('Estonian'),
        id: 'et',
      },
      {
        label: t('Basque'),
        id: 'eu',
      },
      {
        label: t('Persian'),
        id: 'fa',
      },
      {
        label: t('Fulah'),
        id: 'ff',
      },
      {
        label: t('Finnish'),
        id: 'fi',
      },
      {
        label: t('Fijian'),
        id: 'fj',
      },
      {
        label: t('Faroese'),
        id: 'fo',
      },
      {
        label: t('French'),
        id: 'fr',
      },
      {
        label: t('Western Frisian'),
        id: 'fy',
      },
      {
        label: t('Irish'),
        id: 'ga',
      },
      {
        label: t('Gaelic, Scottish Gaelic'),
        id: 'gd',
      },
      {
        label: t('Galician'),
        id: 'gl',
      },
      {
        label: t('Guarani'),
        id: 'gn',
      },
      {
        label: t('Gujarati'),
        id: 'gu',
      },
      {
        label: t('Manx'),
        id: 'gv',
      },
      {
        label: t('Hausa'),
        id: 'ha',
      },
      {
        label: t('Hebrew'),
        id: 'he',
      },
      {
        label: t('Hindi'),
        id: 'hi',
      },
      {
        label: t('Hiri Motu'),
        id: 'ho',
      },
      {
        label: t('Croatian'),
        id: 'hr',
      },
      {
        label: t('Haitian, Haitian Creole'),
        id: 'ht',
      },
      {
        label: t('Hungarian'),
        id: 'hu',
      },
      {
        label: t('Armenian'),
        id: 'hy',
      },
      {
        label: t('Herero'),
        id: 'hz',
      },
      {
        label: t('Interlingua(International Auxiliary Language Association)'),
        id: 'ia',
      },
      {
        label: t('Indonesian'),
        id: 'id',
      },
      {
        label: t('Interlingue, Occidental'),
        id: 'ie',
      },
      {
        label: t('Igbo'),
        id: 'ig',
      },
      {
        label: t('Sichuan Yi, Nuosu'),
        id: 'ii',
      },
      {
        label: t('Inupiaq'),
        id: 'ik',
      },
      {
        label: t('Ido'),
        id: 'io',
      },
      {
        label: t('Icelandic'),
        id: 'is',
      },
      {
        label: t('Italian'),
        id: 'it',
      },
      {
        label: t('Inuktitut'),
        id: 'iu',
      },
      {
        label: t('Japanese'),
        id: 'ja',
      },
      {
        label: t('Javanese'),
        id: 'jv',
      },
      {
        label: t('Georgian'),
        id: 'ka',
      },
      {
        label: t('Kongo'),
        id: 'kg',
      },
      {
        label: t('Kikuyu, Gikuyu'),
        id: 'ki',
      },
      {
        label: t('Kuanyama, Kwanyama'),
        id: 'kj',
      },
      {
        label: t('Kazakh'),
        id: 'kk',
      },
      {
        label: t('Kalaallisut, Greenlandic'),
        id: 'kl',
      },
      {
        label: t('Central Khmer'),
        id: 'km',
      },
      {
        label: t('Kannada'),
        id: 'kn',
      },
      {
        label: t('Korean'),
        id: 'ko',
      },
      {
        label: t('Kanuri'),
        id: 'kr',
      },
      {
        label: t('Kashmiri'),
        id: 'ks',
      },
      {
        label: t('Kurdish'),
        id: 'ku',
      },
      {
        label: t('Komi'),
        id: 'kv',
      },
      {
        label: t('Cornish'),
        id: 'kw',
      },
      {
        label: t('Kirghiz, Kyrgyz'),
        id: 'ky',
      },
      {
        label: t('Latin'),
        id: 'la',
      },
      {
        label: t('Luxembourgish, Letzeburgesch'),
        id: 'lb',
      },
      {
        label: t('Ganda'),
        id: 'lg',
      },
      {
        label: t('Limburgan, Limburger, Limburgish'),
        id: 'li',
      },
      {
        label: t('Lingala'),
        id: 'ln',
      },
      {
        label: t('Lao'),
        id: 'lo',
      },
      {
        label: t('Lithuanian'),
        id: 'lt',
      },
      {
        label: t('Luba-Katanga'),
        id: 'lu',
      },
      {
        label: t('Latvian'),
        id: 'lv',
      },
      {
        label: t('Malagasy'),
        id: 'mg',
      },
      {
        label: t('Marshallese'),
        id: 'mh',
      },
      {
        label: t('Maori'),
        id: 'mi',
      },
      {
        label: t('Macedonian'),
        id: 'mk',
      },
      {
        label: t('Malayalam'),
        id: 'ml',
      },
      {
        label: t('Mongolian'),
        id: 'mn',
      },
      {
        label: t('Marathi'),
        id: 'mr',
      },
      {
        label: t('Malay'),
        id: 'ms',
      },
      {
        label: t('Maltese'),
        id: 'mt',
      },
      {
        label: t('Burmese'),
        id: 'my',
      },
      {
        label: t('Nauru'),
        id: 'na',
      },
      {
        label: t('Norwegian Bokm\u00e5l'),
        id: 'nb',
      },
      {
        label: t('North Ndebele'),
        id: 'nd',
      },
      {
        label: t('Nepali'),
        id: 'ne',
      },
      {
        label: t('Ndonga'),
        id: 'ng',
      },
      {
        label: t('Dutch, Flemish'),
        id: 'nl',
      },
      {
        label: t('Norwegian Nynorsk'),
        id: 'nn',
      },
      {
        label: t('Norwegian'),
        id: 'no',
      },
      {
        label: t('South Ndebele'),
        id: 'nr',
      },
      {
        label: t('Navajo, Navaho'),
        id: 'nv',
      },
      {
        label: t('Chichewa, Chewa, Nyanja'),
        id: 'ny',
      },
      {
        label: t('Occitan'),
        id: 'oc',
      },
      {
        label: t('Ojibwa'),
        id: 'oj',
      },
      {
        label: t('Oromo'),
        id: 'om',
      },
      {
        label: t('Oriya'),
        id: 'or',
      },
      {
        label: t('Ossetian, Ossetic'),
        id: 'os',
      },
      {
        label: t('Panjabi, Punjabi'),
        id: 'pa',
      },
      {
        label: t('Pali'),
        id: 'pi',
      },
      {
        label: t('Polish'),
        id: 'pl',
      },
      {
        label: t('Pashto, Pushto'),
        id: 'ps',
      },
      {
        label: t('Portuguese'),
        id: 'pt',
      },
      {
        label: t('Quechua'),
        id: 'qu',
      },
      {
        label: t('Romansh'),
        id: 'rm',
      },
      {
        label: t('Rundi'),
        id: 'rn',
      },
      {
        label: t('Romanian, Moldavian, Moldovan'),
        id: 'ro',
      },
      {
        label: t('Russian'),
        id: 'ru',
      },
      {
        label: t('Kinyarwanda'),
        id: 'rw',
      },
      {
        label: t('Sanskrit'),
        id: 'sa',
      },
      {
        label: t('Sardinian'),
        id: 'sc',
      },
      {
        label: t('Sindhi'),
        id: 'sd',
      },
      {
        label: t('Northern Sami'),
        id: 'se',
      },
      {
        label: t('Sango'),
        id: 'sg',
      },
      {
        label: t('Sinhala, Sinhalese'),
        id: 'si',
      },
      {
        label: t('Slovak'),
        id: 'sk',
      },
      {
        label: t('Slovenian'),
        id: 'sl',
      },
      {
        label: t('Samoan'),
        id: 'sm',
      },
      {
        label: t('Shona'),
        id: 'sn',
      },
      {
        label: t('Somali'),
        id: 'so',
      },
      {
        label: t('Albanian'),
        id: 'sq',
      },
      {
        label: t('Serbian'),
        id: 'sr',
      },
      {
        label: t('Swati'),
        id: 'ss',
      },
      {
        label: t('Southern Sotho'),
        id: 'st',
      },
      {
        label: t('Sundanese'),
        id: 'su',
      },
      {
        label: t('Swedish'),
        id: 'sv',
      },
      {
        label: t('Swahili'),
        id: 'sw',
      },
      {
        label: t('Tamil'),
        id: 'ta',
      },
      {
        label: t('Telugu'),
        id: 'te',
      },
      {
        label: t('Tajik'),
        id: 'tg',
      },
      {
        label: t('Thai'),
        id: 'th',
      },
      {
        label: t('Tigrinya'),
        id: 'ti',
      },
      {
        label: t('Turkmen'),
        id: 'tk',
      },
      {
        label: t('Tagalog'),
        id: 'tl',
      },
      {
        label: t('Tswana'),
        id: 'tn',
      },
      {
        label: t('Tonga (Tonga Islands)'),
        id: 'to',
      },
      {
        label: t('Turkish'),
        id: 'tr',
      },
      {
        label: t('Tsonga'),
        id: 'ts',
      },
      {
        label: t('Tatar'),
        id: 'tt',
      },
      {
        label: t('Twi'),
        id: 'tw',
      },
      {
        label: t('Tahitian'),
        id: 'ty',
      },
      {
        label: t('Uighur, Uyghur'),
        id: 'ug',
      },
      {
        label: t('Ukrainian'),
        id: 'uk',
      },
      {
        label: t('Urdu'),
        id: 'ur',
      },
      {
        label: t('Uzbek'),
        id: 'uz',
      },
      {
        label: t('Venda'),
        id: 've',
      },
      {
        label: t('Vietnamese'),
        id: 'vi',
      },
      {
        label: t('Volap\u00fck'),
        id: 'vo',
      },
      {
        label: t('Walloon'),
        id: 'wa',
      },
      {
        label: t('Wolof'),
        id: 'wo',
      },
      {
        label: t('Xhosa'),
        id: 'xh',
      },
      {
        label: t('Yiddish'),
        id: 'yi',
      },
      {
        label: t('Yoruba'),
        id: 'yo',
      },
      {
        label: t('Zhuang, Chuang'),
        id: 'za',
      },
      {
        label: t('Chinese'),
        id: 'zh',
      },
      {
        label: t('Zulu'),
        id: 'zu',
      },
    ],
    publishStatuses: [
      {
        label: t('Draft'),
        id: 'draft',
        requiredSetClaim: 'set_draft',
        requiredEditClaim: 'edit_draft',
      },
      {
        label: t('Ready for Review'),
        id: 'ready_for_review',
        requiredSetClaim: 'set_ready_for_review',
        requiredEditClaim: 'edit_ready_for_review',
      },
      {
        label: t('Ready to Publish'),
        id: 'ready_to_publish',
        requiredSetClaim: 'set_ready_to_publish',
        requiredEditClaim: 'edit_ready_to_publish',
      },
      {
        label: t('Published'),
        id: 'published',
        requiredSetClaim: 'set_published',
        requiredEditClaim: 'edit_published',
        disable: true,
      },
      {
        label: t('Archived'),
        id: 'archived',
        requiredSetClaim: 'set_archived',
        requiredEditClaim: 'edit_archived',
      },
    ],
  },
  // used in multiple components
  /**
   * This noDataText displays in 'autocomplete' or 'select' inputs when there's no data for the
   * drop-down list. This happens when items are filtered, data was not loaded from the service,
   * user entered invalid search terms (for autocomplete input), or some error ocurred.
   */
  noDataText: {
    addressSearch: t('Please enter new search terms.'),
    addressMissingCountry: t('Please select Country first.'),
    default: t('No items to display.'),
    mainDiscipline: t('Please select Ambassador Type first.'),
    subDiscipline: t('Please select Main Discipline first.'),
  },
};
