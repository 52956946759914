<template>
  <div>
    <div class="grid-3-col-responsive break-at-1200">
      <!-- STATUS INFORMATION -->
      <h2 class="h-primary section-header top row">
        {{ content.formSectionHeaders.contractDetails }}
      </h2>
      <profile-details-form-field
        :show-form-input="showFormInput('starting_status')"
        :section="section"
        attribute-id="starting_status"
        :display-data="inputSelectionItems.contractStatusLabels[ambassador.starting_status]"
        @edit="enableEdit"
      >
        <template #input>
          <v-select
            id="starting_status"
            v-model="section.formFields.starting_status"
            validate-on-blur
            :items="inputSelectionItems.contractStatusOptions"
            item-text="label"
            item-value="id"
            solo
            flat
            :placeholder="fieldText.starting_status.placeholder"
            persistent-hint
            menu-props="offset-y"
            :disabled="!section.editing"
            @input="validateTerminatedStatus"
          />
        </template>
      </profile-details-form-field>
      <profile-details-form-field
        v-if="showTerminationReasonField"
        :show-form-input="showFormInput('termination_reason')"
        attribute-id="termination_reason"
        :display-data="ambassador.termination_reason"
        :section="section"
        field-type="textarea"
      />
      <profile-details-form-field
        :show-form-input="showFormInput('start_date')"
        :section="section"
        attribute-id="start_date"
        :display-data="ambassador.start_date"
        class="lll-form-field-bottom-padding"
        :edits-disabled="true"
        @edit="enableEdit"
      >
        <template #input>
          <v-menu
            v-model="menuStartDatePicker"
            :close-on-content-click="false"
            full-width
            lazy
            max-width="300px"
          >
            <v-text-field
              id="update-start-date"
              slot="activator"
              v-model="section.formFields.start_date"
              solo
              flat
              readonly
              :placeholder="fieldText.start_date.placeholder"
              :hint="fieldText.start_date.hint"
              persistent-hint
              validate-on-blur
              append-icon="arrow_drop_down"
            />
            <v-date-picker
              ref="startDatePicker"
              v-model="section.formFields.start_date"
              validate-on-blur
              full-width
              no-title
              show-current="false"
              color="primary"
              @input="menuStartDatePicker = false"
            />
          </v-menu>
        </template>
      </profile-details-form-field>
      <profile-details-form-field
        :show-form-input="showFormInput('end_date')"
        :section="section"
        attribute-id="end_date"
        :display-data="ambassador.end_date"
        class="lll-form-field-bottom-padding"
        @edit="enableEdit"
      >
        <template #input>
          <v-menu
            v-model="menuEndDatePicker"
            :close-on-content-click="false"
            full-width
            lazy
            max-width="300px"
          >
            <v-text-field
              id="update-end-date"
              slot="activator"
              v-model="section.formFields.end_date"
              solo
              flat
              readonly
              :placeholder="fieldText.end_date.placeholder"
              :hint="fieldText.end_date.hint"
              persistent-hint
              validate-on-blur
              append-icon="arrow_drop_down"
            />
            <v-date-picker
              ref="endDatePicker"
              v-model="section.formFields.end_date"
              validate-on-blur
              full-width
              no-title
              show-current="false"
              color="primary"
              @input="menuEndDatePicker = false"
            />
          </v-menu>
        </template>
      </profile-details-form-field>
      <profile-details-form-field
        :show-form-input="showFormInput('relationship_manager_name')"
        :section="section"
        attribute-id="relationship_manager_name"
        field-type="text"
        :display-data="ambassador.relationship_manager_name"
        @edit="enableEdit"
      />
      <profile-details-form-field
        :show-form-input="showFormInput('relationship_manager_email')"
        :section="section"
        attribute-id="relationship_manager_email"
        field-type="text"
        :display-data="ambassador.relationship_manager_email"
        @edit="enableEdit"
      />
    </div>
    <div>
      <!-- DOCUMENTS -->
      <h2 class="h-primary section-header top row">
        {{ content.formSectionHeaders.documents }}
      </h2>
      <div>
        <ul class="list-unstyled">
          <li v-for="document in documentsList" :key="document.id" class="lll-hr-grey">
            <div class="document-list-item">
              <v-progress-circular
                v-if="fileIsDownloading(document.id) || fileIsDeleting(document.id)"
                indeterminate
                :size="22"
                :width="2"
              />
              <!-- Users with edit permission see this 'delete' icon -->
              <v-btn
                v-else-if="showDeleteBtn"
                name="delete-file"
                icon
                ripple
                :disabled="fileIsDownloading(document.id) || fileIsDeleting(document.id)"
                @click="confirmDelete(document)"
              >
                <v-icon>delete_forever</v-icon>
              </v-btn>
              <!-- Users with read-only permission see this 'save' icon (they cannot delete) -->
              <v-icon v-else color="secondary" class="save-icon">save_alt</v-icon>
              <button
                v-ripple
                type="button"
                name="download-file"
                class="document-title btn-link lll-text-truncate"
                :disabled="fileIsDownloading(document.id) || fileIsDeleting(document.id)"
                @click="downloadDocument(document)"
              >
                {{ document.filename }}
              </button>
            </div>
          </li>
        </ul>
        <button
          v-show="userPermissions.canEditRecords"
          name="add-documents"
          type="button"
          class="btn btn-add btn-primary text-no-wrap"
          @click="openDocUploader"
        >
          {{ content.documentsButton }}
        </button>
        <amb-modal :open-modal.sync="docUploaderIsOpen">
          <div class="uploader-container">
            <div class="uploader-layout lll-bg-grey">
              <div class="uploader-body row">
                <button
                  v-if="loading"
                  name="btn-loading"
                  class="btn btn-primary pl-5 pr-5"
                  disabled
                >
                  <v-progress-circular :indeterminate="true" />
                </button>
                <div v-show="!loading" class="btn-group-1-col pt-0">
                  <button
                    name="file-picker-multiple"
                    class="btn file-picker-button"
                    :disabled="disableFilePicker"
                    @click.prevent="launchFilePickerMultiple"
                  >
                    {{ content.filepickerButton }}
                  </button>
                  <input
                    v-show="false"
                    id="input-add-documents"
                    ref="filePickerMultiple"
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    multiple
                    @change="handleFileUploads"
                  />
                </div>
                <div class="lll-vr-grey lll-hr-grey divider-responsive" />
                <div>
                  <h1 class="h-label mb-3">
                    {{ content.fileRequirements.heading }}
                  </h1>
                  <h2 class="h-primary mb-2">
                    {{ content.fileRequirements.fileType }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </amb-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import fileDownload from 'js-file-download';
import { mapActions, mapGetters } from 'vuex';
import ProfileDetailsFormField from '@/components/ProfileDetailsFormField';
import * as formHelpers from '@/utils/formHelpers';
import get from '@/utils/config';
import { getAuthToken } from '@/vuex/modules/auth';
import content from '@/content/staticContentForUI';
import AMBModal from '@/components/AMBModal';
import fileTypeChecker from 'file-type-checker';

export default {
  name: 'ProfileDetailsStatusAndContract',

  components: {
    'profile-details-form-field': ProfileDetailsFormField,
    'amb-modal': AMBModal,
  },

  props: {
    ambassador: {
      required: true,
      type: Object,
      default: () => {},
    },
    section: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      content: content.profileDetailsStatusAndContract,
      inputSelectionItems: content.inputSelectionItems,
      fieldText: content.formFields,
      menuStartDatePicker: false,
      menuEndDatePicker: false,
      docUploaderIsOpen: false,
      loading: false,
      disableFilePicker: false,
      filesBeingDeleted: [],
      filesBeingDownloaded: [],
      enableTerminationReasonField: false,
    };
  },

  computed: {
    ...mapGetters(['experiences', 'userClaims', 'userPermissions', 'currentAmbassadorDocuments']),

    documentsList() {
      return this.currentAmbassadorDocuments.docsList;
    },

    showDeleteBtn() {
      return this.userPermissions.canEditRecords;
    },

    showTerminationReasonField() {
      return this.ambassador.starting_status === 'terminated' || this.enableTerminationReasonField;
    },
  },

  methods: {
    ...mapActions(['deleteAmbassadorDocument', 'getAmbassadorDocuments', 'saveNewDocument']),

    beginFileDelete(documentId) {
      this.filesBeingDeleted.push(documentId);
    },

    beginFileDownload(documentId) {
      this.filesBeingDownloaded.push(documentId);
    },

    closeDocUploader() {
      this.docUploaderIsOpen = false;
    },

    confirmDelete(document) {
      const {
        heading,
        confirm,
        warning,
        cancelButton,
        confirmButton,
      } = this.content.confirmDeleteAlert;

      const confirmDeleteAlert = this.$createAlert(heading, `<p>${confirm}</p><p>${warning}</p>`);

      this.$swal({
        ...confirmDeleteAlert,
        showCancelButton: true,
        cancelButtonText: cancelButton,
        confirmButtonText: confirmButton,
      }).then(result => {
        if (result.value) {
          this.deleteDocument(document);
        }
      });
    },

    deleteDocument(document) {
      this.beginFileDelete(document.id);

      const payload = {
        ambassador_id: this.ambassador.id,
        document_id: document.id,
      };

      this.deleteAmbassadorDocument(payload)
        .then(() => {
          this.endFileDelete(document.id);
          this.getAmbassadorDocuments(this.ambassador.id);
        })
        .catch(error => {
          this.endFileDelete(document.id);
          this.$handleErrorMessage(error);
        });
    },

    downloadDocument(document) {
      this.beginFileDownload(document.id);

      const urlHost = get('VUE_APP_AMBASSADORS_HOST');
      const urlPath = `/v2/documents/${this.ambassador.id}/${document.id}/download`;
      const downloadURL = `${urlHost}${urlPath}`;

      /**
       * We tried to implement this with a 302 redirect. However, Amazon does not allow both the
       * signed paramaters and an auth header in the same reqeust. So we make a manual redirect
       * (using first 'downloadURL' to get a second 'download_url') in order to make a clean
       * request, because the headers are automatically included in the next call.
       */
      axios
        .create({
          headers: {
            Authorization: getAuthToken(),
          },
        })
        .get(downloadURL)
        .then(response => {
          axios({
            method: 'get',
            url: response.data.download_url,
            responseType: 'blob',
          })
            .then(response => {
              fileDownload(response.data, document.filename);
              this.endFileDownload(document.id);
            })
            .catch(error => {
              this.$handleErrorMessage(error);
              this.endFileDownload(document.id);
            });
        })
        .catch(error => {
          this.$handleErrorMessage(error);
          this.endFileDownload(document.id);
        });
    },

    endFileDelete(documentId) {
      this.filesBeingDeleted = this.filesBeingDeleted.filter(item => item !== documentId);
    },

    endFileDownload(documentId) {
      this.filesBeingDownloaded = this.filesBeingDownloaded.filter(item => item !== documentId);
    },

    fileIsDeleting(documentId) {
      return this.filesBeingDeleted.includes(documentId);
    },

    fileIsDownloading(documentId) {
      return this.filesBeingDownloaded.includes(documentId);
    },

    handleFileUploads() {
      const fileList = this.$refs.filePickerMultiple.files;

      this.loading = true;
      sessionStorage.setItem('fileUploadInProgress', true);

      this.saveDocuments(fileList)
        .then(() => {
          sessionStorage.removeItem('fileUploadInProgress');
          this.getAmbassadorDocuments(this.ambassador.id).then(() => {
            this.loading = false;
            this.closeDocUploader();
          });
        })
        .catch(error => {
          this.getAmbassadorDocuments(this.ambassador.id);
          this.$handleErrorMessage(error);
          sessionStorage.removeItem('fileUploadInProgress');
          this.loading = false;
        });
    },

    launchFilePickerMultiple() {
      this.resetFilePicker();
      this.$refs.filePickerMultiple.click();
    },

    openDocUploader() {
      this.docUploaderIsOpen = true;
    },

    resetFilePicker() {
      this.$refs.filePickerMultiple.value = '';
    },

    async saveDocuments(fileList) {
      const types = ['jpeg', 'png', 'gif', 'pdf'];
      const promises = [];
      for (let idx = 0; idx < fileList.length; idx++) {
        const promise = new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.onload = async () => {
              const isValidType = fileTypeChecker.validateFileType(reader.result, types);
              if (isValidType) {
                const savedDocument = await this.saveNewDocument({
                  ambassadorId: this.ambassador.id,
                  file: fileList[idx],
                });
                resolve(savedDocument);
              }
              reject('Invalid file type');
            };
            reader.readAsArrayBuffer(fileList[idx]);
          } catch (e) {
            reject(e);
          }
        });
        promises.push(promise);
      }

      const docs = await Promise.all(promises)
        .then(values => {
          return values;
        })
        .catch(error => {
          throw new Error(error);
        });
      return docs;
    },

    showFormInput(attributeId) {
      if (this.userPermissions && !this.userPermissions.canEditRecords) {
        console.log('BINGO User does not have permission to edit records');
        return false;
      }

      const inEditMode = this.section.editing;
      /**
       * If field is in 'edit mode', show input.
       * If data not yet saved with ambassador record, show input.
       */
      return inEditMode || formHelpers.fieldIsEmpty(attributeId, this.ambassador);
    },

    enableEdit() {
      this.$emit('edit');
    },

    validateTerminatedStatus(inputValue) {
      this.enableTerminationReasonField = false;
      if (inputValue === 'terminated') {
        const { heading, terminated, confirmButton, cancelButton } = this.content.confirmationAlert;
        const basicAlertConfig = this.$createAlert(heading, `<p>${terminated}</p>`);
        this.$swal({
          ...basicAlertConfig,
          showCancelButton: true,
          cancelButtonText: cancelButton,
          confirmButtonText: confirmButton,
        }).then(result => {
          if (result.dismiss) {
            // "CANCEL" button was clicked
            // reset "Contract Status" form field to original value before input was changed
            this.section.formFields.starting_status = this.ambassador.starting_status;
          } else {
            // "OK" button was clicked
            // show text field to collect termination reason, so it's present when form is saved
            this.enableTerminationReasonField = true;
          }
        });
      }
    },
    resetSpecialFields() {
      this.enableTerminationReasonField = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';
$breakpoint-med: 1000px;

@mixin lll-btn-hover() {
  &:hover {
    background-color: $lll-color-charcoal;
    color: $lll-color-white;
    border: 1px solid $lll-color-charcoal;
  }
}

.experiences-section {
  display: grid;
  grid-column-gap: 50px;
  grid-template:
    'one'
    'two'
    'three'
    'four'
    /
    1fr;

  @media (min-width: 1000px) {
    grid-template:
      'one one'
      'two three'
      'four four'
      /
      1fr 1fr;
  }
}

.experiences-field-1 {
  grid-area: one;
}

.experiences-field-2 {
  grid-area: two;
}

.experiences-field-3 {
  grid-area: three;
}

.experiences-field-4 {
  grid-area: four;
}

.experiences-input {
  padding: 5px 0 0 10px;
}

.list-item-icon-with-text {
  display: grid;
  grid-template-columns: min-content minmax(50px, 1fr);
  align-items: start;
  margin-bottom: 10px;
}

.uploader-container {
  padding: 5vw;

  @media (min-width: $breakpoint-med) {
    padding: 50px 7vw;
  }
}

.uploader-layout {
  display: grid;
  grid-template-columns: 1fr minmax(75%, 90%) 1fr;
  padding: 5vw;
}

.uploader-body {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 3vw;
  padding: 10vmin 0;
  align-items: center;
  justify-items: center;

  @media (min-width: $breakpoint-med) {
    grid-column: 2;
    grid-template-columns: 1fr min-content 1fr;
  }
}

.document-title {
  padding: 10px;
  text-align: left;
}

.document-list-item {
  display: grid;
  grid-template-columns: min-content minmax(50px, 1fr);
  align-items: center;
}

.btn-add {
  width: 20%;
  height: 50%;
  margin-top: 2%;
}
</style>
